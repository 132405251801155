import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import SeoHelmet from '../components/SeoHelmet';
import DefaultLayout from '../components/DefaultLayout';

const Title = styled.h1`
  margin: 48px 0;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const SectionTitle = styled.h2`
  margin: 48px 0 24px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const PrivacySection = styled.div`
  position: relative;
  margin: 140px 0 72px;
`;

const PrivacyPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query PrivacyPageQuery {
      site {
        siteMetadata {
          siteName
          siteUrl
          emails {
            hello
          }
          links {
            privacy
            terms
          }
        }
      }
    }
  `);
  const siteName = _.get(data, 'site.siteMetadata.siteName');
  const siteUrl = _.get(data, 'site.siteMetadata.siteUrl');
  const privacyUrl = _.get(data, 'site.siteMetadata.links.privacy');
  const termsUrl = _.get(data, 'site.siteMetadata.links.terms');
  const helloEmail = _.get(data, 'site.siteMetadata.emails.hello');

  return (
    <DefaultLayout>
      <SeoHelmet
        location={location}
        title="Политика конфиденциальности"
        description="Порядок сбора, хранения и обработки персональных данных пользователей Nerdia"
      />
      <PrivacySection>
        <Title>Политика конфиденциальности</Title>

        <p>
          Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
          «О персональных данных» и определяет порядок обработки персональных
          данных и меры по обеспечению безопасности персональных данных во время
          использования веб-сайта с сетевым адресом{' '}
          <a href={siteUrl}>{siteUrl}</a> (далее – <b>Сайт {siteName}</b>),
          предпринимаемые Гуровым Германом Георгиевичем (далее – <b>Оператор</b>
          ).
        </p>
        <p>
          Перед использованием Сайта {siteName}, пожалуйста, ознакомьтесь с
          условиями настоящей Политики Конфиденциальности (далее –{' '}
          <b>Политика</b>).
        </p>
        <SectionTitle>1. Общие положения</SectionTitle>
        <p>
          1.1. Оператор ставит своей важнейшей целью и условием осуществления
          своей деятельности соблюдение прав и свобод человека и гражданина при
          обработке его персональных данных, в том числе защиты прав на
          неприкосновенность частной жизни, личную и семейную тайну.
        </p>
        <p>
          1.2. Настоящая политика Оператора в отношении обработки персональных
          данных применяется ко всей информации, которую Оператор может получить
          о посетителях Сайта {siteName} (далее – <b>Пользователь</b>).
        </p>
        <p>
          1.3. Пользуясь Сайтом {siteName}, Пользователь соглашается с тем, что:
        </p>
        <p>
          1.3.1. начало использования Сайта {siteName} в любой форме означает,
          что Пользователь принимаете все условия настоящей Политики в полном
          объеме без каких-либо изъятий и ограничений со стороны Пользователя;
        </p>
        <p>
          1.3.2. если Пользователь не согласен с условиями настоящей Политики,
          Пользователю следует незамедлительно прекратить любое использование
          Сайта {siteName};
        </p>
        <p>
          1.3.3. Политика (в том числе любая из ее частей) может быть изменена
          Оператором без какого-либо специального уведомления и без выплаты
          какой-либо компенсации в связи с этим. Новая редакция Политики
          вступает в силу с момента ее размещения на Сайте {siteName}{' '}
          Оператором, если иное не предусмотрено новой редакцией Политики.
        </p>

        <SectionTitle>
          2. Оператор может получать и обрабатывать следующие персональные
          данные Пользователя
        </SectionTitle>
        <p>
          2.1. Настоящая Политика распространяется на следующие виды
          персональной информации:
        </p>
        <p>
          2.1.1. персональная информация, размещаемая Пользователями, в т.ч. о
          себе самостоятельно при регистрации, иная персональная информация,
          доступ к которой Пользователь предоставляет Оператору через веб-сайты
          или сервисы третьих лиц, или персональная информация, размещаемая
          Пользователями в процессе использования Сайта {siteName}. К
          персональной информации, полученной таким образом, относятся имя,
          фамилия, сведения об образовании, профессия, специальность,
          квалификация, стаж работы и адрес электронной почты Пользователя.
          Обязательная для использования Сайта {siteName} или отдельных их
          функциональных возможностей информация помечена специальным образом.
          Иная информация предоставляется Пользователем на его усмотрение.
          Запрещается предоставление Пользователем персональных данных третьих
          лиц без полученного от третьих лиц разрешения на такое распространение
          либо, если такие персональные данные третьих лиц не были получены
          самим Пользователем из общедоступных источников информации;
        </p>
        <p>
          2.1.2 обезличенные данные о посетителях (в т.ч. файлов «cookie»),
          собранные и обработанные с помощью сервисов интернет-статистики
          (Яндекс Метрика, Гугл Аналитика и других);
        </p>
        <p>
          2.1.3. данные, дополнительно предоставляемые Пользователями по запросу
          Оператора в целях выполнения обязательств Оператора перед
          Пользователями в отношении использования Сайта {siteName};
        </p>
        <p>
          2.1.4. иная информация о Пользователях, сбор и/или обработка которой
          установлены Пользовательским соглашением, расположенным по сетевому
          адресу <a href={termsUrl}>{termsUrl}</a>.
        </p>
        <p>
          2.2. Вышеперечисленные данные далее по тексту Политики объединены
          общим понятием <b>Персональные данные</b>.
        </p>
        <SectionTitle>
          3. Цели сбора и обработки Персональных данных
        </SectionTitle>
        <p>
          3.1. Оператор осуществляет сбор и обработку только тех Персональных
          данных о Пользователях, которые необходимы для выполнения обязательств
          Оператора по предоставлению услуг на Сайте {siteName}.
        </p>
        <p>
          3.2. Оператор может использовать Персональные данные Пользователей для
          целей:
        </p>
        <p>
          3.2.1. предоставления доступа Пользователю к сервисам, информации и
          материалам, содержащимся на Сайте {siteName};
        </p>
        <p>3.2.2. реагирования на запросы Пользователей в службу поддержки;</p>
        <p>
          3.2.3. информирования Пользователей о возможностях Сайта {siteName} и
          выполнения маркетинговых задач (включая осуществление маркетинговых
          рассылок по электронной почте);
        </p>
        <p>
          3.2.4. направления запросов, касающихся использования Сайта {siteName}
          ;
        </p>
        <p>3.2.5. улучшения качества сайта и его содержания.</p>
        <p>
          3.3. При необходимости использовать Персональные данные о Пользователе
          в целях, не предусмотренных настоящей Политикой, Оператор запрашивает
          согласие Пользователя на такие действия.
        </p>
        <SectionTitle>
          4. Порядок сбора, хранения, передачи и других видов обработки
          Персональных данных
        </SectionTitle>
        <p>
          4.1. Оператор обеспечивает сохранность Персональных данных и принимает
          все возможные меры, исключающие доступ к Персональным данным
          неуполномоченных лиц.
        </p>
        <p>
          4.2. Персональные данные о Пользователе не передается третьим лицам,
          за исключением следующих случаев:
        </p>
        <p>4.2.1. Пользователь выразил согласие на такие действия;</p>
        <p>
          4.2.2. передача необходима в целях обеспечения функционирования Сайта
          {siteName}, реализации партнерских и иных программ Сайта {siteName};
        </p>
        <p>4.2.3. передача предусмотрена применимым правом;</p>
        <p>
          4.2.4. в целях обеспечения возможности защиты прав и законных
          интересов Оператора и/или третьих лиц в случаях, когда Пользователь
          нарушает условия соглашений об использовании Сайта {siteName};
        </p>
        <p>
          4.2.5. если Оператор примет участие в слиянии, приобретении или любой
          другой форме продажи части или всех своих активов. При этом к
          приобретателю активов Оператора переходят все обязательства по
          соблюдению условий настоящей Политики.
        </p>
        <p>
          4.3. Кроме того, Пользователь настоящим уведомлен и соглашается, что
          Оператор может получать статистические обезличенные (без привязки к
          Пользователю) данные о действиях Пользователя при использовании Сайта
          {siteName} в случае, если это разрешено в настройках браузера
          Пользователя (включено сохранение файлов «cookie» и использование
          технологии JavaScript).
        </p>
        <p>
          4.4. Базы данных информации, содержащей Персональные данные
          Пользователей-граждан Российской Федерации, находятся на территории
          Российской Федерации.
        </p>
        <SectionTitle>5. Права Пользователя</SectionTitle>
        <p>5.1. Пользователь вправе:</p>
        <p>
          5.1.1. в случае выявления неточностей актуализировать Персональные
          данные самостоятельно, путем внесения изменений о себе в Личном
          кабинете на Сайте {siteName};
        </p>
        <p>
          5.1.2. в любой момент отозвать свое согласие на обработку персональных
          данных, направив Оператору уведомление по электронной почте{' '}
          <a href={`mailto:${helloEmail}`}>{helloEmail}</a> с пометкой «Отзыв
          согласия на обработку персональных данных». При этом удаление профиля
          может повлечь невозможность использования некоторых разделов Сайта{' '}
          {siteName}, включая Личный кабинет Пользователя;
        </p>
        <p>
          5.1.3. получить любые разъяснения по интересующим вопросам, касающимся
          обработки его персональных данных, обратившись к Оператору по
          электронной почте <a href={`mailto:${helloEmail}`}>{helloEmail}</a>;
        </p>
        <p>
          5.2.4. направлять Оператору запросы, предложения или вопросы,
          касающиеся настоящей Политики, по электронной почте{' '}
          <a href={`mailto:${helloEmail}`}>{helloEmail}</a>.
        </p>
        <SectionTitle>6. Заключительные положения</SectionTitle>
        <p>
          6.1. Актуальная версия Политики в свободном доступе расположена в сети
          Интернет по адресу <a href={privacyUrl}>{privacyUrl}</a>.
        </p>
        <p>
          6.2. Настоящая Политика, отношения между Пользователем и Оператором,
          возникающие в связи с применением настоящей Политики, а также вопросы,
          не урегулированные настоящей Политикой, регулируются действующим
          законодательством Российской Федерации.
        </p>
      </PrivacySection>
    </DefaultLayout>
  );
};

export default PrivacyPage;
